import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/index";
import FooterSection from "./components/Footer-section";
import { MainRoutes } from "./constants/routes";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {MainRoutes.map(({ Component, path }) => (
            <Route element={<Component />} path={path} key={path} />
          ))}
        </Routes>
      </Suspense>
      <FooterSection />
    </Router>
  );
}

export default App;
