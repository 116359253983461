import { useLocation, useNavigate } from "react-router-dom";
import NispandLogo from "../../assets/Images/NispandLogo.png";
import "./styles.scss";
import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import Hamburger from "../../assets/Icons/Hamburger.svg";
import closeIcon from "../../assets/Icons/CloseIcon.svg";
import TwitterIcon from "../../assets/Icons/TwitterIcon.svg";
import LinkedInIcon from "../../assets/Icons/LinkedInIcon.svg";
import FacebookIcon from "../../assets/Icons/FacebookIcon.svg";
import GithubIcon from "../../assets/Icons/GithubIcon.svg";
import HandsIcon from "../../assets/Icons/HandsIcon.svg";
import DribbleIcon from "../../assets/Icons/DribbleIcon.svg";

const Navbar = () => {
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  // const [activeNavItem, setActiveNavItem] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      import("bootstrap/dist/js/bootstrap.bundle.min.js")
        .then((bootstrap) => { })
        .catch((error) => {
          console.error("Error loading Bootstrap:", error);
          console.log("Error loading Bootstrap:", error);
        });
    };
  }, []);

  const handleNavigation = (num) => {
    switch (num) {
      case 1:
        if (location.pathname === "/") {
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate("/");
        } else {
          navigate("/");
        }
        break;
      case 2:
        navigate("/about");
        break;
      case 3:
        navigate("/business");
        break;
      case 4:
        navigate("/terms-condition");
        break;
      case 5:
        navigate("/privacy-policy");
        break;
      case 6:
        navigate("/#pricing");
        break;
      case 7:
        navigate("/blog");
        break;
      case 8:
        navigate("/contact");
        break;
      default:
    }
  };

  const handleMain = () => {
    setIsSidenavOpen(false);
  };

  const handleToggleSidenav = () => {
    setIsSidenavOpen(!isSidenavOpen);
  };

  const handleButtonClick = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.nispand.com&hl=en_IN&gl=US";
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.location.href = "https://apps.apple.com/in/app/nispand-meditation-and-sleep/id1609608907";
    } else if (/Macintosh|Mac OS X/i.test(userAgent)) {
      window.location.href = "https://apps.apple.com/in/app/nispand-meditation-and-sleep/id1609608907";
    } else {
      window.location.href = "https://play.google.com/store/apps/details?id=com.nispand.com&hl=en_IN&gl=US";
    }
  };


  return (
    <nav className={`${scrolled ? "scrolled" : ""}`}>
      <div className="nav-container">
        <div className="nav-wrapper">
          <div className="nav-left-section">
            <div className="nispandLogo">
              <img
                src={NispandLogo}
                alt="Nispand Logo"
                onClick={() => handleNavigation(1)}
              />
            </div>
            <div className="nav-items">
              <div
                className={`nav-item ${location.pathname === "/" ? "nav-item-active" : ""
                  }`}
                onClick={() => handleNavigation(1)}
              >
                Home
              </div>
              <div
                className={`nav-item ${location.pathname === "/blog" ? "nav-item-active" : ""
                  }`}
                onClick={() => handleNavigation(7)}
              >
                Blogs
              </div>
              <div
                className={`nav-item ${location.pathname === "/about" ? "nav-item-active" : ""
                  }`}
                onClick={() => handleNavigation(2)}
              >
                About Us
              </div>
              <ScrollLink
                to="pricing"
                smooth={true}
                duration={500}
                onClick={() => {
                  handleNavigation(6);
                }}
                className={`nav-item ${location.pathname === "/#pricing" ? "nav-item-active" : ""
                  }`}
                style={{ textDecoration: "none" }}
              >
                Pricing
              </ScrollLink>

              <div
                className={`nav-item ${location.pathname === "/business" ? "nav-item-active" : ""
                  }`}
                onClick={() => handleNavigation(3)}
              >
                Business
              </div>
            </div>
          </div>
          <div className="nav-right-section">
            <a
              style={{ textDecoration: "none" }}
              href="https://business.nispand.com/auth/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="partner-login">Partner Login</button>
            </a>
            <button className="download-btn" onClick={handleButtonClick}>Download Nispand</button>
          </div>
          <img
            className="hamburger"
            src={Hamburger}
            alt="Hamburger icon"
            onClick={handleToggleSidenav}
          />
          {isSidenavOpen && (
            <div
              className={`sidenav ${isSidenavOpen ? "open" : ""}`}
              style={{ width: "100%" }}
            >
              <div className="d-flex">
                <div className="close-btn">
                  <img
                    src={closeIcon}
                    className="close-icon"
                    onClick={handleToggleSidenav}
                    alt="Close Icon"
                  />
                </div>
                <img
                  src={NispandLogo}
                  className="brandLogo1 margin-67"
                  alt="Nispand Logo"
                />
              </div>
              <div className="accordian-bg">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      {/* <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      > */}
                        Company
                      {/* </button> */}
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <ScrollLink
                            to="home"
                            smooth={true}
                            duration={500}
                            onClick={() => {
                              handleMain();
                              handleNavigation(1);
                            }}
                          >
                            Home
                          </ScrollLink>
                        </p>
                        <p>
                          <ScrollLink
                            to="home"
                            smooth={true}
                            duration={500}
                            onClick={() => {
                              handleMain();
                              handleNavigation(7);
                            }}
                          >
                            Blog
                          </ScrollLink>
                        </p>
                        <p>
                          <ScrollLink
                            to="about"
                            smooth={true}
                            duration={500}
                            onClick={() => {
                              handleMain();
                              handleNavigation(2);
                            }}
                          >
                            About Us
                          </ScrollLink>
                        </p>
                        <p>
                          <ScrollLink
                            to="Metadata"
                            smooth={true}
                            duration={500}
                            onClick={() => {
                              handleMain();
                              handleNavigation(3);
                            }}
                          >
                            Nispand Business
                          </ScrollLink>
                        </p>
                        <p>
                          <ScrollLink
                            to="Analytics"
                            smooth={true}
                            duration={500}
                            onClick={() => {
                              handleMain();
                              handleNavigation(8);
                            }}
                          >
                            Contact
                          </ScrollLink>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      {/* <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      > */}
                        Policies
                      {/* </button> */}
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <ScrollLink
                            onClick={() => {
                              handleMain();
                              handleNavigation(4);
                            }}
                          >
                            Terms and Conditions
                          </ScrollLink>
                        </p>
                        <p>
                          <ScrollLink
                            onClick={() => {
                              handleMain();
                              handleNavigation(5);
                            }}
                          >
                            Privacy Policy
                          </ScrollLink>
                        </p>
                        <p>
                          <ScrollLink
                            to="contact"
                            smooth={true}
                            duration={500}
                            onClick={handleMain}
                          >
                            Terms of Use
                          </ScrollLink>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="">
            <a
              style={{ textDecoration: "none", marginRight: '25px'}}
              href="https://business.nispand.com/auth/login"
              target="_blank"
              rel="noopener noreferrer"
              className="partner-login"
            >
              Partner Login
            </a>
            <a className="download-btn" onClick={handleButtonClick}>Download Nispand</a>
          </div>
                </div>
                <div className="accordian5">
                  <div>
                    <div className="call-us">
                      <div className="call-us1">
                        <div className="call-us2">We are social</div>
                      </div>
                      <div className="d-flex pt-16">
                        <img src={TwitterIcon} alt="Twitter Icon" onClick={() => window.open("https://twitter.com/NispandApp", "_blank")} />
                        <img src={LinkedInIcon} alt="LinkedIn Icon" onClick={() => window.open("https://www.linkedin.com/company/nispand/about/", "_blank")} />
                        <img src={FacebookIcon} alt="Facebook Icon" onClick={() => window.open("https://www.facebook.com/Nispand.TheYogaInstitute", "_blank")} />
                        <img src={GithubIcon} alt="Github Icon" />
                        <img src={HandsIcon} alt="Hands Icon" />
                        <img src={DribbleIcon} alt="Dribble Icon" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copyright-content1 p-24">
                  © {currentYear} Nispand All rights reserved.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
