import React, { Suspense, lazy } from "react";
const Disclaimer = lazy(() => import('../Views/UserDeletionDisclaimer'));
const DetailedBlogPage = lazy(() => import('../Views/DetailedBlogPage'));
const BusinessPage = lazy(() => import('../Views/Business'));
const Privacy = lazy(() => import('../Views/PrivacyPolicy'));
const TermsOfService = lazy(() => import('../Views/TermsOfService'));
const Contact = lazy(() => import('../Views/ContactUs'));
const Blog = lazy(() => import('../Views/Blog'));
const AboutUs = lazy(() => import('../Views/About'));
const Home = lazy(() => import('../Views/Home'));


export const MainRoutes = [
  
  {
    Component: Home,
    path: "/",
    exact: true,
    route: "/",
    name: "Home",
  },
  {
    Component: Blog,
    path: "/blog",
    exact: true,
    route: "/blog",
    name: "Blog",
  },
  {
    Component: Contact,
    path: "/contact",
    exact: true,
    route: "/contact",
    name: "ContactUs",
  },
  {
    Component: BusinessPage,
    path: "/business",
    exact: true,
    route: "/business",
    name: "Business",
  },
  {
    Component: AboutUs,
    path: "/about",
    exact: true,
    route: "/about",
    name: "AboutUs",
  },
  {
    Component: DetailedBlogPage,
    path: "/blog/:itemId",
    exact: true,
    route: "/blog/:itemId",
    name: "DetailedBlogPage",
  },
  // {
  //   Component: GoogleAuth,
  //   path: "/google-auth",
  //   exact: true,
  //   route: "/google-auth",
  //   name: "GoogleAuth",
  // },
  {
    Component: TermsOfService,
    path: "/terms-condition",
    exact: true,
    route: "/terms-condition",
    name: "terms-condition",
  },
  {
    Component: Privacy,
    path: "/privacy-policy",
    exact: true,
    route: "/privacy-policy",
    name: "privacy-policy",
  },
  {
    Component: Disclaimer,
    path: "/user-deletion-disclaimer",
    exact: true,
    route: "/user-deletion-disclaimer",
    name: "Disclaimer",
  },
];
